@import "../../style/themes/default.scss";
.header {
  height: 50px;
  line-height: 50px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .ant-alert-message {
    padding-left: 5px;
  }
  .ant-alert-warning {
    border-radius: 0px 0px 12px 12px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .avatarContainer {
    padding-left: 0px;
    padding-right: 0px;
    color: inherit;
    overflow-x: hidden;
    height: 48px;
    .buttonText {
      display: inline-block;
    }
    .ellipsis {
      max-width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    svg {
      font-size: 16px;
    }
  }
  .ant-divider-vertical {
    top: 5px;
    margin-right: 10px;
    margin-left: 10px;
    height: 40px;
  }
  .menuTitle {
    min-width: 135px;
    height: 50px;
  }
}

.topMenu {
  min-width: 155px;
  .menuItemTitle {
    color: black;
    cursor: default;
    font-weight: 500;
  }
}
.notificationMenu {
  min-width: 250px;
  max-height: 600px;
  overflow: auto;
}

.menuItemText {
  white-space: pre-wrap;
  width: 300px;
}
.menuItemContentFooter span {
  font-size: 12px;
}

@media screen and (max-width: $screen-xs) {
  .header {
    .menuTitle.company {
      display: none;
    }
    .divider.company {
      display: none;
    }
  }
}
