.tableContainer {
  .printHeader {
    display: none;
  }
}
@media print {
  .tableContainer {
    margin: 25px;
    padding-top: 25px;
    .printHeader {
      display: block;
    }
  }
}
