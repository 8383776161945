.dashboard-card-numbers-container {
  padding-top: 20px;
  .ant-card {
    padding-top: 0px;
    width: 100%;
    margin-top: 0px;
    border-radius: 0.625rem;
    min-height: 125px;
    .icon-container {
      padding-top: 35px;
      padding-right: 15px;
    }
  }
  .card-title,
  .card-footer,
  .card-content-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .card-title {
    display: inline-block;
    flex: 1 1;
    padding-top: 16px;
    padding-bottom: 5px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    font-weight: 500 !important;
  }
  .card-footer {
    text-align: left;
    color: #858181;
    font-size: 10px;
    font-weight: 500;
  }
  .ant-card-body {
    padding-left: 10px;
    padding-right: 10px;
  }
  .font-weight-bold {
    font-weight: 700 !important;
  }
  h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }
}
