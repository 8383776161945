.ant-layout {
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

.ant-layout-header {
  background: #fff;
  padding: 0;
}

.ant-layout-content {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 480px;
  height: 100%;
  @media (max-width: 375px) {
    padding: 0px;
    margin: 0px;
  }
}

.ant-layout-sider {
  background-color: #fff;
}
.mainlayout-main-container {
  .logo {
    position: relative;
    overflow: hidden;
    height: 50px;
    padding-left: 11px;
    box-shadow: 0px 1px 0 0 #e8e8e8;
    margin-top: 0px;
  }
}
