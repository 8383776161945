@import "../../style/themes/default";

.quickKeysLayout-container {
  .quickKeys {
    margin-top: 30px;
    margin-bottom: 30px;
    max-height: 580px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 40px;
  }
}

@media screen and (max-height: 820px) {
  .quickKeysLayout-container {
    .quickKeys {
      max-height: 420px;
    }
  }
}
