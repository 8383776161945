.sell-container {
  height: 100%;
  min-height: 100%;

  margin-left: 0px !important;
  margin-right: 0px !important;
}
.sell-product-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
  .quickKeysLayout-container {
    padding-top: 18px;
    @media (max-height: 820px) {
      max-height: 570px;
    }
    max-height: 730px;

    overflow-y: auto;
    overflow-x: hidden;
  }
}
.search-product-container {
  font-size: 12px;
  padding-right: 12px !important;
  white-space: normal;
  .price {
    float: right;
    font-weight: bold;
  }
  .subSection {
    font-size: 10px;
  }
}
