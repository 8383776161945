@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-input-prefix i {
  color: rgba(0, 0, 0, 0.25); }

button.ant-btn {
  border-radius: 10px;
  max-width: 200px; }

.emptylayout-main-container button.ant-btn {
  max-width: 100%; }

.ant-layout {
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden; }

.ant-layout-header {
  background: #fff;
  padding: 0; }

.ant-layout-content {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 480px;
  height: 100%; }
  @media (max-width: 375px) {
    .ant-layout-content {
      padding: 0px;
      margin: 0px; } }

.ant-layout-sider {
  background-color: #fff; }

.mainlayout-main-container .logo {
  position: relative;
  overflow: hidden;
  height: 50px;
  padding-left: 11px;
  box-shadow: 0px 1px 0 0 #e8e8e8;
  margin-top: 0px; }

.ant-menu {
  padding-top: 16px;
  padding-bottom: 16px; }

.ant-menu-inline,
.ant-menu-inline-collapsed {
  border-right: 0px; }

.sider {
  flex: 0 0 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
  width: 200px !important;
  min-height: 100vh;
  box-shadow: 1px 0 3px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10; }
  .sider.light {
    box-shadow: 1px 0 3px 0 rgba(29, 35, 41, 0.05);
    background-color: white; }
    .sider.light .ant-menu-light {
      border-right-color: transparent; }
  .sider .anticon {
    font-size: 18px !important; }

.ant-layout-sider-collapsed {
  flex: 0 0 65px !important;
  max-width: 65px !important;
  min-width: 65px !important;
  width: 65px !important; }

.logo h1 {
  color: black;
  display: inline-block;
  vertical-align: super;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  margin-left: 14px;
  margin-top: 13px;
  font-weight: bold; }

.trigger {
  font-size: 18px;
  line-height: 50px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.header {
  height: 50px;
  line-height: 50px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08); }
  .header .ant-alert-message {
    padding-left: 5px; }
  .header .ant-alert-warning {
    border-radius: 0px 0px 12px 12px;
    padding-left: 5px;
    padding-right: 5px; }
  .header .avatarContainer {
    padding-left: 0px;
    padding-right: 0px;
    color: inherit;
    overflow-x: hidden;
    height: 48px; }
    .header .avatarContainer .buttonText {
      display: inline-block; }
    .header .avatarContainer .ellipsis {
      max-width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .header .avatarContainer svg {
      font-size: 16px; }
  .header .ant-divider-vertical {
    top: 5px;
    margin-right: 10px;
    margin-left: 10px;
    height: 40px; }
  .header .menuTitle {
    min-width: 135px;
    height: 50px; }

.topMenu {
  min-width: 155px; }
  .topMenu .menuItemTitle {
    color: black;
    cursor: default;
    font-weight: 500; }

.notificationMenu {
  min-width: 250px;
  max-height: 600px;
  overflow: auto; }

.menuItemText {
  white-space: pre-wrap;
  width: 300px; }

.menuItemContentFooter span {
  font-size: 12px; }

@media screen and (max-width: 480px) {
  .header .menuTitle.company {
    display: none; }
  .header .divider.company {
    display: none; } }

.particles-container {
  width: 100%;
  height: 90%; }
  .particles-container > div {
    width: 100%;
    height: 100%; }

.emptylayout-main-container {
  background: linear-gradient(135deg, #f0f2f5 0%, #ffffff 100%);
  width: 100%;
  height: 100%;
  position: absolute; }
  .emptylayout-main-container .logo {
    position: relative;
    overflow: hidden;
    margin: 0px;
    margin-bottom: 20px; }

.tableContainer .printHeader {
  display: none; }

@media print {
  .tableContainer {
    margin: 25px;
    padding-top: 25px; }
    .tableContainer .printHeader {
      display: block; } }

.subscription-container .secondaryTitle {
  font-size: 14px;
  font-weight: bold; }

.subscription-container .secondaryText {
  font-size: 14px; }

.offline-container {
  text-align: center;
  font-size: 40px;
  padding-top: 150px;
  color: gray; }

.login-form-container {
  top: 45%;
  position: absolute;
  width: 100%;
  margin-top: -200px;
  z-index: 100; }

.login-form {
  max-width: 300px;
  margin: 0 auto; }

.login-form-forgot {
  float: right; }

.login-form-button {
  width: 100%;
  margin-bottom: 15px; }

.signup-form-container {
  top: 45%;
  position: absolute;
  width: 100%;
  margin-top: -300px;
  z-index: 100; }

.signup-form {
  max-width: 300px;
  margin: 0 auto; }

.save-button {
  width: 100%;
  margin-bottom: 15px; }

.forgotPassword-form-container {
  top: 45%;
  position: absolute;
  width: 100%;
  margin-top: -200px;
  z-index: 100; }

.forgotPassword-form {
  max-width: 300px;
  margin: 0 auto; }

.forgotPassword-success {
  max-width: 500px;
  margin: 0 auto; }

.forgotPassword-form-button {
  width: 100%;
  margin-bottom: 15px; }

.resetPassword-form-container {
  top: 45%;
  position: absolute;
  width: 100%;
  margin-top: -200px;
  z-index: 100; }

.resetPassword-form {
  max-width: 300px;
  margin: 0 auto; }

.resetPassword-success {
  max-width: 500px;
  margin: 0 auto; }

.resetPassword-form-button {
  width: 100%;
  margin-bottom: 15px; }

.dashboard-card-numbers-container {
  padding-top: 20px; }
  .dashboard-card-numbers-container .ant-card {
    padding-top: 0px;
    width: 100%;
    margin-top: 0px;
    border-radius: 0.625rem;
    min-height: 125px; }
    .dashboard-card-numbers-container .ant-card .icon-container {
      padding-top: 35px;
      padding-right: 15px; }
  .dashboard-card-numbers-container .card-title,
  .dashboard-card-numbers-container .card-footer,
  .dashboard-card-numbers-container .card-content-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%; }
  .dashboard-card-numbers-container .card-title {
    display: inline-block;
    flex: 1 1;
    padding-top: 16px;
    padding-bottom: 5px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    font-weight: 500 !important; }
  .dashboard-card-numbers-container .card-footer {
    text-align: left;
    color: #858181;
    font-size: 10px;
    font-weight: 500; }
  .dashboard-card-numbers-container .ant-card-body {
    padding-left: 10px;
    padding-right: 10px; }
  .dashboard-card-numbers-container .font-weight-bold {
    font-weight: 700 !important; }
  .dashboard-card-numbers-container h1 {
    font-size: 22px;
    margin-bottom: 10px; }

.sell-container {
  height: 100%;
  min-height: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important; }

.sell-product-container {
  padding-left: 0px !important;
  padding-right: 0px !important; }
  .sell-product-container .quickKeysLayout-container {
    padding-top: 18px;
    max-height: 730px;
    overflow-y: auto;
    overflow-x: hidden; }
    @media (max-height: 820px) {
      .sell-product-container .quickKeysLayout-container {
        max-height: 570px; } }

.search-product-container {
  font-size: 12px;
  padding-right: 12px !important;
  white-space: normal; }
  .search-product-container .price {
    float: right;
    font-weight: bold; }
  .search-product-container .subSection {
    font-size: 10px; }

.sell-bucket-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 100%;
  box-shadow: -1px 4px 7px -4px rgba(0, 21, 41, 0.08); }
  .sell-bucket-container .sell-bucket-actions {
    text-align: left;
    padding-bottom: 8px; }
    .sell-bucket-container .sell-bucket-actions button {
      padding-right: 5px !important;
      padding-left: 5px !important;
      font-size: 13px; }
  .sell-bucket-container .ant-divider-horizontal {
    margin: 5px 0; }
  .sell-bucket-container .productHeader {
    width: 100%; }
  .sell-bucket-container.readonly .priceContainer {
    padding-right: unset !important; }
  .sell-bucket-container.readonly .productHeader,
  .sell-bucket-container.readonly .paySubTotalHeader,
  .sell-bucket-container.readonly .paySubTotalTax {
    padding-left: 12px;
    color: rgba(0, 0, 0, 0.85); }

.sell-bucket-form-button {
  width: 100%;
  height: 45px; }
  .sell-bucket-form-button.refund {
    background-color: #e6643c;
    border-color: #e6643c; }

.sell-bucket-form-total-title,
.sell-bucket-form-button-title {
  text-align: left;
  padding-left: 8px; }

.sell-bucket-form-button-title .ant-typography {
  color: white; }

.sell-bucket-form-total-price,
.sell-bucket-form-button-price {
  text-align: right;
  font-size: 20px; }

.sell-bucket-form-total-price {
  padding-right: 8px; }

.sell-bucket-form {
  height: 85%;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  position: absolute;
  left: 0;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%; }
  .sell-bucket-form .top {
    flex: 1 1;
    flex-grow: 1;
    overflow: auto;
    /* for Firefox */
    min-height: 0; }
  .sell-bucket-form .bottom {
    background-color: #f0f2f5; }
  .sell-bucket-form .hiddenFormFields {
    display: none; }
  .sell-bucket-form .ant-collapse {
    border: unset;
    background-color: unset; }
  .sell-bucket-form .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0px; }
  .sell-bucket-form .ant-collapse-content {
    border-top: unset;
    background-color: unset; }
  .sell-bucket-form .ant-collapse-item {
    border-left: 3px solid transparent; }
    .sell-bucket-form .ant-collapse-item.ant-collapse-item-active {
      border-left: 3px solid #40a9ff; }
  .sell-bucket-form .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 5px;
    padding-right: 8px;
    padding-top: 10px;
    padding-bottom: 8px; }
    .sell-bucket-form .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      left: 5px;
      top: 34%; }
  .sell-bucket-form .ant-form-item {
    margin-bottom: 10px; }
  .sell-bucket-form .ant-divider {
    margin: 0px 0; }
  .sell-bucket-form .form-item.left {
    text-align: left; }
  .sell-bucket-form .form-item.right {
    text-align: right; }
  .sell-bucket-form .productHeader .subSection,
  .sell-bucket-form .paySubTotalHeader .subSection,
  .sell-bucket-form .paySubTotalTax .subSection {
    font-size: 12px;
    min-height: 22px; }
    .sell-bucket-form .productHeader .subSection .priceContainer,
    .sell-bucket-form .paySubTotalHeader .subSection .priceContainer,
    .sell-bucket-form .paySubTotalTax .subSection .priceContainer {
      padding-right: 27px;
      text-decoration: line-through; }
  .sell-bucket-form .productHeader .priceContainer,
  .sell-bucket-form .paySubTotalHeader .priceContainer,
  .sell-bucket-form .paySubTotalTax .priceContainer {
    float: right;
    padding-left: 5px; }
    .sell-bucket-form .productHeader .priceContainer .anticon,
    .sell-bucket-form .paySubTotalHeader .priceContainer .anticon,
    .sell-bucket-form .paySubTotalTax .priceContainer .anticon {
      padding-left: 10px; }
  .sell-bucket-form .paySubTotalHeader .subSection .priceContainer {
    padding-right: 5px; }
  .sell-bucket-form .productHeader .subSection {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

@media screen and (min-height: 769px) {
  .sell-bucket-form {
    height: 85%; } }

@media screen and (max-width: 480px) {
  .sell-bucket-container {
    height: 94%; }
  .sell-bucket-form {
    height: 85%; } }

.ant-divider-horizontal {
  margin: 5px 0px;
  margin-top: 10px; }

.menu {
  width: 250px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 500px; }
  .menu .date,
  .menu .note {
    font-size: 11px; }

.save-button {
  width: 100%; }

.quickkeys-item-container {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.quickkeys-item {
  margin-left: 0px;
  margin-right: 25px;
  min-width: 145px;
  width: 145px; }

.quickkeys-card-container {
  width: 160px; }
  .quickkeys-card-container .card {
    border: 1px solid #dfdbdb;
    border-radius: 20px;
    padding: 5px; }
  .quickkeys-card-container .card-edit {
    cursor: move; }
  .quickkeys-card-container .ant-card-body {
    padding: 5px;
    height: 50px; }
  .quickkeys-card-container .ant-card-meta-title {
    margin-bottom: 0px !important; }
  .quickkeys-card-container .ant-card-meta-avatar {
    padding-left: 2px;
    padding-right: 2px; }
  .quickkeys-card-container .ant-card-meta-description {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .quickkeys-card-container .ant-card-meta-title {
    font-size: 13px; }
  .quickkeys-card-container .card-cover {
    height: 80px !important;
    width: 100%;
    flex: 1 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    text-align: center; }

.sell-bucket-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 100%;
  box-shadow: -1px 4px 7px -4px rgba(0, 21, 41, 0.08); }
  .sell-bucket-container .sell-bucket-actions {
    text-align: left;
    padding-bottom: 8px; }
    .sell-bucket-container .sell-bucket-actions button {
      padding-right: 5px !important;
      padding-left: 5px !important;
      font-size: 13px; }
  .sell-bucket-container .ant-divider-horizontal {
    margin: 5px 0; }
  .sell-bucket-container .productHeader {
    width: 100%; }
  .sell-bucket-container.readonly .priceContainer {
    padding-right: unset !important; }
  .sell-bucket-container.readonly .productHeader,
  .sell-bucket-container.readonly .paySubTotalHeader,
  .sell-bucket-container.readonly .paySubTotalTax {
    padding-left: 12px;
    color: rgba(0, 0, 0, 0.85); }

.sell-bucket-form-button {
  width: 100%;
  height: 45px; }
  .sell-bucket-form-button.refund {
    background-color: #e6643c;
    border-color: #e6643c; }

.sell-bucket-form-total-title,
.sell-bucket-form-button-title {
  text-align: left;
  padding-left: 8px; }

.sell-bucket-form-button-title .ant-typography {
  color: white; }

.sell-bucket-form-total-price,
.sell-bucket-form-button-price {
  text-align: right;
  font-size: 20px; }

.sell-bucket-form-total-price {
  padding-right: 8px; }

.sell-bucket-form {
  height: 85%;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  position: absolute;
  left: 0;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%; }
  .sell-bucket-form .top {
    flex: 1 1;
    flex-grow: 1;
    overflow: auto;
    /* for Firefox */
    min-height: 0; }
  .sell-bucket-form .bottom {
    background-color: #f0f2f5; }
  .sell-bucket-form .hiddenFormFields {
    display: none; }
  .sell-bucket-form .ant-collapse {
    border: unset;
    background-color: unset; }
  .sell-bucket-form .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0px; }
  .sell-bucket-form .ant-collapse-content {
    border-top: unset;
    background-color: unset; }
  .sell-bucket-form .ant-collapse-item {
    border-left: 3px solid transparent; }
    .sell-bucket-form .ant-collapse-item.ant-collapse-item-active {
      border-left: 3px solid #40a9ff; }
  .sell-bucket-form .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 5px;
    padding-right: 8px;
    padding-top: 10px;
    padding-bottom: 8px; }
    .sell-bucket-form .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      left: 5px;
      top: 34%; }
  .sell-bucket-form .ant-form-item {
    margin-bottom: 10px; }
  .sell-bucket-form .ant-divider {
    margin: 0px 0; }
  .sell-bucket-form .form-item.left {
    text-align: left; }
  .sell-bucket-form .form-item.right {
    text-align: right; }
  .sell-bucket-form .productHeader .subSection,
  .sell-bucket-form .paySubTotalHeader .subSection,
  .sell-bucket-form .paySubTotalTax .subSection {
    font-size: 12px;
    min-height: 22px; }
    .sell-bucket-form .productHeader .subSection .priceContainer,
    .sell-bucket-form .paySubTotalHeader .subSection .priceContainer,
    .sell-bucket-form .paySubTotalTax .subSection .priceContainer {
      padding-right: 27px;
      text-decoration: line-through; }
  .sell-bucket-form .productHeader .priceContainer,
  .sell-bucket-form .paySubTotalHeader .priceContainer,
  .sell-bucket-form .paySubTotalTax .priceContainer {
    float: right;
    padding-left: 5px; }
    .sell-bucket-form .productHeader .priceContainer .anticon,
    .sell-bucket-form .paySubTotalHeader .priceContainer .anticon,
    .sell-bucket-form .paySubTotalTax .priceContainer .anticon {
      padding-left: 10px; }
  .sell-bucket-form .paySubTotalHeader .subSection .priceContainer {
    padding-right: 5px; }
  .sell-bucket-form .productHeader .subSection {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

@media screen and (min-height: 769px) {
  .sell-bucket-form {
    height: 85%; } }

@media screen and (max-width: 480px) {
  .sell-bucket-container {
    height: 94%; }
  .sell-bucket-form {
    height: 85%; } }

.pay-container .sell-bucket-form {
  height: 560px;
  overflow-y: auto;
  position: unset;
  padding-left: 5px;
  padding-right: 15px; }
  .pay-container .sell-bucket-form .bottom {
    background-color: white; }
  .pay-container .sell-bucket-form .ant-collapse-item.ant-collapse-item-active {
    border-left: 0px !important; }
  .pay-container .sell-bucket-form .ant-collapse-content > .ant-collapse-content-box {
    margin-left: 10px;
    padding-top: 0px;
    font-size: 12px; }
  .pay-container .sell-bucket-form .ant-collapse > .ant-collapse-item {
    margin-left: -15px; }

.pay-container .amountToPay-container {
  text-align: right;
  padding-left: 20px !important; }
  .pay-container .amountToPay-container .amountToPay-actionButtons {
    padding-top: 10px; }
    .pay-container .amountToPay-container .amountToPay-actionButtons button {
      width: 100%; }

.pay-container .totalAmount-input {
  width: 120px; }

.pay-container .totalAmount-input-container {
  padding-left: 15px;
  text-align: left; }

.pay-container .amountToPay-input-container {
  min-height: 80px;
  text-align: left;
  padding-left: 10px; }

.pay-container .payments-container .change,
.pay-container .payments-container .payments {
  padding-top: 5px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px; }

.pay-container .payments-container .payments:last-child {
  padding-bottom: 10px; }

.pay-container .payments-container .change {
  padding-bottom: 10px; }

.pay-container .payments-container .toPay {
  padding-left: 10px;
  padding-right: 10px; }
  .pay-container .payments-container .toPay .price {
    font-size: 20px; }

.pay-container .receipt-container {
  font-family: "JetBrains Mono", monospace; }
  .pay-container .receipt-container .receipt-header {
    display: none; }
  .pay-container .receipt-container .receipt-footer {
    display: none; }
  .pay-container .receipt-container .barcode-container {
    text-align: center; }

@media screen and (min-height: 769px) {
  .pay-container .sell-bucket-form {
    height: 650px; } }

@media screen and (max-width: 480px) {
  .pay-container .sell-bucket-form {
    height: 530px; } }

.sell-bucket-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 100%;
  box-shadow: -1px 4px 7px -4px rgba(0, 21, 41, 0.08); }
  .sell-bucket-container .sell-bucket-actions {
    text-align: left;
    padding-bottom: 8px; }
    .sell-bucket-container .sell-bucket-actions button {
      padding-right: 5px !important;
      padding-left: 5px !important;
      font-size: 13px; }
  .sell-bucket-container .ant-divider-horizontal {
    margin: 5px 0; }
  .sell-bucket-container .productHeader {
    width: 100%; }
  .sell-bucket-container.readonly .priceContainer {
    padding-right: unset !important; }
  .sell-bucket-container.readonly .productHeader,
  .sell-bucket-container.readonly .paySubTotalHeader,
  .sell-bucket-container.readonly .paySubTotalTax {
    padding-left: 12px;
    color: rgba(0, 0, 0, 0.85); }

.sell-bucket-form-button {
  width: 100%;
  height: 45px; }
  .sell-bucket-form-button.refund {
    background-color: #e6643c;
    border-color: #e6643c; }

.sell-bucket-form-total-title,
.sell-bucket-form-button-title {
  text-align: left;
  padding-left: 8px; }

.sell-bucket-form-button-title .ant-typography {
  color: white; }

.sell-bucket-form-total-price,
.sell-bucket-form-button-price {
  text-align: right;
  font-size: 20px; }

.sell-bucket-form-total-price {
  padding-right: 8px; }

.sell-bucket-form {
  height: 85%;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  position: absolute;
  left: 0;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%; }
  .sell-bucket-form .top {
    flex: 1 1;
    flex-grow: 1;
    overflow: auto;
    /* for Firefox */
    min-height: 0; }
  .sell-bucket-form .bottom {
    background-color: #f0f2f5; }
  .sell-bucket-form .hiddenFormFields {
    display: none; }
  .sell-bucket-form .ant-collapse {
    border: unset;
    background-color: unset; }
  .sell-bucket-form .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0px; }
  .sell-bucket-form .ant-collapse-content {
    border-top: unset;
    background-color: unset; }
  .sell-bucket-form .ant-collapse-item {
    border-left: 3px solid transparent; }
    .sell-bucket-form .ant-collapse-item.ant-collapse-item-active {
      border-left: 3px solid #40a9ff; }
  .sell-bucket-form .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 5px;
    padding-right: 8px;
    padding-top: 10px;
    padding-bottom: 8px; }
    .sell-bucket-form .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      left: 5px;
      top: 34%; }
  .sell-bucket-form .ant-form-item {
    margin-bottom: 10px; }
  .sell-bucket-form .ant-divider {
    margin: 0px 0; }
  .sell-bucket-form .form-item.left {
    text-align: left; }
  .sell-bucket-form .form-item.right {
    text-align: right; }
  .sell-bucket-form .productHeader .subSection,
  .sell-bucket-form .paySubTotalHeader .subSection,
  .sell-bucket-form .paySubTotalTax .subSection {
    font-size: 12px;
    min-height: 22px; }
    .sell-bucket-form .productHeader .subSection .priceContainer,
    .sell-bucket-form .paySubTotalHeader .subSection .priceContainer,
    .sell-bucket-form .paySubTotalTax .subSection .priceContainer {
      padding-right: 27px;
      text-decoration: line-through; }
  .sell-bucket-form .productHeader .priceContainer,
  .sell-bucket-form .paySubTotalHeader .priceContainer,
  .sell-bucket-form .paySubTotalTax .priceContainer {
    float: right;
    padding-left: 5px; }
    .sell-bucket-form .productHeader .priceContainer .anticon,
    .sell-bucket-form .paySubTotalHeader .priceContainer .anticon,
    .sell-bucket-form .paySubTotalTax .priceContainer .anticon {
      padding-left: 10px; }
  .sell-bucket-form .paySubTotalHeader .subSection .priceContainer {
    padding-right: 5px; }
  .sell-bucket-form .productHeader .subSection {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

@media screen and (min-height: 769px) {
  .sell-bucket-form {
    height: 85%; } }

@media screen and (max-width: 480px) {
  .sell-bucket-container {
    height: 94%; }
  .sell-bucket-form {
    height: 85%; } }

.pay-container .sell-bucket-form {
  height: 560px;
  overflow-y: auto;
  position: unset;
  padding-left: 5px;
  padding-right: 15px; }
  .pay-container .sell-bucket-form .bottom {
    background-color: white; }
  .pay-container .sell-bucket-form .ant-collapse-item.ant-collapse-item-active {
    border-left: 0px !important; }
  .pay-container .sell-bucket-form .ant-collapse-content > .ant-collapse-content-box {
    margin-left: 10px;
    padding-top: 0px;
    font-size: 12px; }
  .pay-container .sell-bucket-form .ant-collapse > .ant-collapse-item {
    margin-left: -15px; }

.pay-container .amountToPay-container {
  text-align: right;
  padding-left: 20px !important; }
  .pay-container .amountToPay-container .amountToPay-actionButtons {
    padding-top: 10px; }
    .pay-container .amountToPay-container .amountToPay-actionButtons button {
      width: 100%; }

.pay-container .totalAmount-input {
  width: 120px; }

.pay-container .totalAmount-input-container {
  padding-left: 15px;
  text-align: left; }

.pay-container .amountToPay-input-container {
  min-height: 80px;
  text-align: left;
  padding-left: 10px; }

.pay-container .payments-container .change,
.pay-container .payments-container .payments {
  padding-top: 5px;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px; }

.pay-container .payments-container .payments:last-child {
  padding-bottom: 10px; }

.pay-container .payments-container .change {
  padding-bottom: 10px; }

.pay-container .payments-container .toPay {
  padding-left: 10px;
  padding-right: 10px; }
  .pay-container .payments-container .toPay .price {
    font-size: 20px; }

.pay-container .receipt-container {
  font-family: "JetBrains Mono", monospace; }
  .pay-container .receipt-container .receipt-header {
    display: none; }
  .pay-container .receipt-container .receipt-footer {
    display: none; }
  .pay-container .receipt-container .barcode-container {
    text-align: center; }

@media screen and (min-height: 769px) {
  .pay-container .sell-bucket-form {
    height: 650px; } }

@media screen and (max-width: 480px) {
  .pay-container .sell-bucket-form {
    height: 530px; } }

.sellHistory-container .pay-container .sell-bucket-form {
  height: 100%;
  max-height: 500px; }

.purchase-form .save-button {
  margin-bottom: 2px; }

.purchaseProducts-container {
  min-height: calc(100vh - 595px);
  max-height: calc(100vh - 340px);
  overflow-x: hidden; }

.purchaseProducts-footer .productNameContainer,
.purchaseProducts-row .productNameContainer {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.purchaseProducts-footer .productName,
.purchaseProducts-row .productName {
  font-size: 11px; }

.purchaseProducts-footer .barcode,
.purchaseProducts-row .barcode {
  font-size: 10px; }

.purchaseProducts-footer .variant,
.purchaseProducts-row .variant {
  font-size: 10px; }

.purchaseProducts-footer .ant-picker,
.purchaseProducts-footer input,
.purchaseProducts-row .ant-picker,
.purchaseProducts-row input {
  font-size: 12px;
  line-height: 12px;
  height: 20px;
  padding: unset;
  padding-left: 5px;
  padding-right: 5px; }

.purchaseProducts-footer .ant-form-item-explain-error,
.purchaseProducts-row .ant-form-item-explain-error {
  font-size: 12px; }

.purchaseProducts-footer {
  font-size: 12px;
  margin-top: 18px !important; }

.purchaseoverview-purchaseProducts-container .productName,
.purchaseoverview-purchaseProducts-container .barcode,
.purchaseoverview-purchaseProducts-container .batchNumber,
.purchaseoverview-purchaseProducts-container .expiryDate,
.purchaseoverview-purchaseProducts-container .quantity,
.purchaseoverview-purchaseProducts-container .purchasePrice {
  font-size: 12px; }

.purchaseoverview-purchaseProducts-container .variant {
  font-size: 10px; }

.purchaseoverview-purchaseProducts-container .barcode {
  font-size: 12px; }

.purchaseoverview-purchaseProducts-container .expand-purchaseProducts-header {
  font-size: 12px; }

.purchaseoverview-purchaseProducts-container .ant-switch-inner {
  font-size: 10px; }

.purchaseoverview-purchaseProducts-container button {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px; }

.quickKeysLayout-container .quickKeys {
  margin-top: 30px;
  margin-bottom: 30px;
  max-height: 580px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 40px; }

@media screen and (max-height: 820px) {
  .quickKeysLayout-container .quickKeys {
    max-height: 420px; } }

.ant-card-body {
  padding: unset; }

.ant-form-item {
  margin: 0 0 12px; }

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}
.edit-tag {
  -webkit-user-select: none;
          user-select: none;
  margin-bottom: 10px;
}
.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}
[data-theme="dark"] .site-tag-plus {
  background: transparent;
  border-style: dashed;
}

.product-container {
  max-height: calc(100vh - 167px);
  overflow-y: auto;
  overflow-x: hidden; }

.variantsDefinitionsContainer .variantDefinitionHeaderTitleContainer {
  padding-left: 40px;
  padding-top: 20px; }

.variantsDefinitionsContainer .variantDefinitionHeaderContainer {
  margin-bottom: -55px;
  margin-top: -5px;
  width: 100%; }
  .variantsDefinitionsContainer .variantDefinitionHeaderContainer .variantDefinitionHeader-text {
    padding-top: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }



.report-footer-container {
  width: 100%;
  padding-top: 10px;
  float: right; }
  .report-footer-container .secondaryTitle {
    font-size: 14px;
    font-weight: bold; }
  .report-footer-container .secondaryText {
    font-size: 14px; }
  .report-footer-container .primaryTitle {
    font-size: 18px;
    font-weight: bold; }
  .report-footer-container .primaryText {
    font-size: 16px;
    font-weight: bold; }


