.ant-divider-horizontal {
  margin: 5px 0px;
  margin-top: 10px;
}
.menu {
  width: 250px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 500px;

  .date,
  .note {
    font-size: 11px;
  }
}
