.ant-input-prefix {
  i {
    color: rgba(0, 0, 0, 0.25);
  }
}
button.ant-btn {
  border-radius: 10px;
  max-width: 200px;
}

.emptylayout-main-container {
  button.ant-btn {
    max-width: 100%;
  }
}
