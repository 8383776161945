@import "../../../style/themes/default";

.sell-bucket-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 100%;
  box-shadow: -1px 4px 7px -4px rgba(0, 21, 41, 0.08);
  .sell-bucket-actions {
    text-align: left;
    padding-bottom: 8px;
    button {
      padding-right: 5px !important;
      padding-left: 5px !important;
      font-size: 13px;
    }
  }
  .ant-divider-horizontal {
    margin: 5px 0;
  }
  .productHeader {
    width: 100%;
  }

  &.readonly {
    .priceContainer {
      padding-right: unset !important;
    }

    .productHeader,
    .paySubTotalHeader,
    .paySubTotalTax {
      padding-left: 12px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.sell-bucket-form-button {
  width: 100%;
  height: 45px;
  &.refund {
    background-color: #e6643c;
    border-color: #e6643c;
  }
}

.sell-bucket-form-total-title,
.sell-bucket-form-button-title {
  text-align: left;
  padding-left: 8px;
}

.sell-bucket-form-button-title {
  .ant-typography {
    color: white;
  }
}

.sell-bucket-form-total-price,
.sell-bucket-form-button-price {
  text-align: right;
  font-size: 20px;
}

.sell-bucket-form-total-price {
  padding-right: 8px;
}

.sell-bucket-form {
  height: 85%;
  display: flex;
  flex-direction: column;
  min-height: 0px;

  position: absolute;
  // top: 0;
  // bottom: 0;
  left: 0;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;

  .top {
    flex: 1;
    flex-grow: 1;
    overflow: auto;
    /* for Firefox */
    min-height: 0;
  }

  .bottom {
    // padding-left: 5px;
    // padding-right: 5px;
    background-color: #f0f2f5;
  }

  .hiddenFormFields {
    display: none;
  }
  .ant-collapse {
    border: unset;
    background-color: unset;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0px;
  }
  .ant-collapse-content {
    border-top: unset;
    background-color: unset;
  }

  .ant-collapse-item {
    border-left: 3px solid transparent;
    &.ant-collapse-item-active {
      border-left: 3px solid #40a9ff;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 5px;
    padding-right: 8px;
    padding-top: 10px;
    padding-bottom: 8px;
    .ant-collapse-arrow {
      left: 5px;
      top: 34%;
    }
  }

  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-divider {
    margin: 0px 0;
  }
  .form-item {
    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
  }
  .productHeader,
  .paySubTotalHeader,
  .paySubTotalTax {
    .subSection {
      font-size: 12px;
      min-height: 22px;

      .priceContainer {
        padding-right: 27px;
        text-decoration: line-through;
      }
    }
    .priceContainer {
      float: right;
      padding-left: 5px;
      .anticon {
        padding-left: 10px;
      }
    }
  }

  .paySubTotalHeader {
    .subSection {
      .priceContainer {
        padding-right: 5px;
      }
    }
  }

  .productHeader {
    .subSection {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

@media screen and (min-height: ($screen-md + 1)) {
  .sell-bucket-form {
    height: 85%;
  }
}

@media screen and (max-width: $screen-xs) {
  .sell-bucket-container {
    height: 94%;
  }
  .sell-bucket-form {
    height: 85%;
  }
}
