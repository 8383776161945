.quickkeys-item-container {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.quickkeys-item {
  margin-left: 0px;
  margin-right: 25px;
  min-width: 145px;
  width: 145px;
}
.quickkeys-card-container {
  width: 160px;
  .card {
    border: 1px solid #dfdbdb;
    border-radius: 20px;
    padding: 5px;
  }
  .card-edit {
    cursor: move;
  }
  .ant-card-body {
    padding: 5px;
    height: 50px;
  }
  .ant-card-meta-title {
    margin-bottom: 0px !important;
  }
  .ant-card-meta-avatar {
    padding-left: 2px;
    padding-right: 2px;
  }
  .ant-card-meta-description {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ant-card-meta-title {
    font-size: 13px;
  }

  .card-cover {
    height: 80px !important;
    width: 100%;
    flex: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    text-align: center;
  }
}
