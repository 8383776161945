@import "../../../style/themes/default.scss";
@import "../../../components/Sell/Bucket/index.scss";

.pay-container {
  .sell-bucket-form {
    height: 560px;
    overflow-y: auto;
    position: unset;
    padding-left: 5px;
    padding-right: 15px;

    .bottom {
      background-color: white;
    }

    .ant-collapse-item.ant-collapse-item-active {
      border-left: 0px !important;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      margin-left: 10px;
      padding-top: 0px;
      font-size: 12px;
    }

    .ant-collapse > .ant-collapse-item {
      margin-left: -15px;
    }
  }
  .amountToPay-container {
    text-align: right;
    padding-left: 20px !important;

    .amountToPay-actionButtons {
      padding-top: 10px;
      button {
        width: 100%;
      }
    }
  }

  .totalAmount-input {
    width: 120px;
  }

  .totalAmount-input-container {
    padding-left: 15px;
    text-align: left;
  }

  .amountToPay-input-container {
    min-height: 80px;
    text-align: left;
    padding-left: 10px;
  }

  .payments-container {
    .change,
    .payments {
      padding-top: 5px;
      background-color: white;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
    }
    .payments:last-child {
      padding-bottom: 10px;
    }
    .change {
      padding-bottom: 10px;
    }
    .toPay {
      padding-left: 10px;
      padding-right: 10px;
      .price {
        font-size: 20px;
      }
    }
  }

  .receipt-container {
    font-family: "JetBrains Mono", monospace;
    .receipt-header {
      display: none;
    }
    .receipt-footer {
      display: none;
    }
    .barcode-container {
      text-align: center;
    }
  }

  @media screen and (min-height: ($screen-md + 1)) {
    .sell-bucket-form {
      height: 650px;
    }
  }

  @media screen and (max-width: $screen-xs) {
    .sell-bucket-form {
      height: 530px;
    }
  }
}

// @media print {
//   @page {
//     margin: 0;
//     padding: 0;
//     display: block;
//   }
//   .receipt-header {
//     padding-bottom: 5px;
//     .title {
//       text-align: center;
//       font-size: 14px;
//       font-weight: bold;
//       padding-bottom: 5px;
//     }
//     .customerName {
//       text-align: left;
//       font-size: 12px;
//       padding-top: 5px;
//       border-bottom: 1px solid black;
//     }
//     .date {
//       text-align: left;
//       font-size: 10px;
//       padding-top: 5px;
//     }
//     .headerText {
//       white-space: pre-wrap;
//       text-align: left;
//       font-size: 12px;
//       padding-top: 5px;
//       padding-bottom: 10px;
//       margin-bottom: 10px;
//       border-bottom: 1px solid black;
//     }
//   }
//   .receipt-footer {
//     .notes,
//     .footerText {
//       text-align: left;
//       white-space: pre-wrap;
//       font-size: 12px;
//     }
//     .footerText {
//       padding-top: 10px;
//       margin-top: 10px;
//       padding-bottom: 10px;
//       border-top: 1px solid black;
//       border-bottom: 1px solid black;
//       margin-bottom: 10px;
//     }
//     .poweredBy {
//       font-size: 12px;
//       margin-top: 10px;
//     }
//   }
//   .barcode-container {
//     text-align: center;
//   }
//   .receipt-container.print {
//     font-family: "JetBrains Mono", monospace;
//     font-weight: 600;
//     padding-top: 25px;
//     background-color: white;
//     width: 100%;
//     text-align: left;
//     padding-left: 5px;
//     padding-right: 15px;

//     .deletePaymentButton {
//       display: none;
//     }

//     .ant-divider-horizontal {
//       border-top: 3px solid black;
//     }

//     .ant-collapse {
//       border: unset;
//       background-color: unset;
//     }

//     .ant-collapse > .ant-collapse-item {
//       border-bottom: 0px solid white;
//       border-radius: 0px;
//     }
//     .ant-collapse-content {
//       border-top: unset;
//       background-color: unset;
//     }

//     .ant-collapse-item {
//       border-left: 0px solid transparent;

//       &.ant-collapse-item-active {
//         border-left: 0px solid white;
//       }
//     }

//     .ant-collapse > .ant-collapse-item > .ant-collapse-header {
//       padding-left: 0px;
//       padding-right: 0px;
//       padding-top: 0px;
//       padding-bottom: 0px;
//       color: black;
//     }

//     .ant-form-item {
//       margin-bottom: 10px;
//     }
//     .ant-divider {
//       margin: 0px 0;
//     }
//     .form-item {
//       &.left {
//         text-align: left;
//       }
//       &.right {
//         text-align: right;
//       }
//     }

//     .sell-bucket-form-total-title {
//       padding-left: 0px;
//     }

//     h4,
//     .toPay,
//     .payments-container,
//     .payments,
//     .sell-bucket-form-total-title,
//     .sell-bucket-form-total-price,
//     .productHeader,
//     .paySubTotalHeader,
//     .paySubTotalTax {
//       color: black;
//       font-family: inherit;
//       font-size: 12px;

//       .subSection {
//         font-size: 12px;
//         min-height: 22px;

//         .priceContainer {
//           padding-right: 0px;
//           text-decoration: line-through;
//         }
//       }
//       .priceContainer {
//         float: right;
//         padding-left: 0px;
//         .anticon {
//           padding-left: 0px;
//         }
//       }
//     }

//     .payments {
//       font-size: 10px;
//       padding: 0px;
//     }
//     .paySubTotalTax {
//       .subSection {
//         display: none;
//       }
//     }
//     .expandTaxDetil-container {
//       .ant-collapse-content.ant-collapse-content-active {
//         display: none;
//       }
//     }

//     .sell-bucket-form-total-price {
//       padding-right: 0px;
//     }
//     .toPay,
//     .sell-bucket-form-total-title,
//     .sell-bucket-form-total-price,
//     .paySubTotalHeader {
//       font-weight: bold;
//       padding-top: 2px;
//       padding-bottom: 2px;
//       .subSection {
//         font-size: 10px;
//         font-weight: normal;
//         .priceContainer {
//           padding-right: 0px;
//         }
//       }
//     }

//     .toPay {
//       .price {
//         font-family: inherit;
//         font-size: 12px;
//       }
//     }

//     .productHeader {
//       width: 100%;
//       .variant {
//         font-size: 10px;
//       }
//     }
//   }
// }
