@import url("https://fonts.googleapis.com/css?family=Montserrat");
.logo {
  h1 {
    color: black;
    display: inline-block;
    vertical-align: super;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    margin-left: 14px;
    margin-top: 13px;
    font-weight: bold;
  }
}
