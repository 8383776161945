.product-container {
  max-height: calc(100vh - 167px);
  overflow-y: auto;
  overflow-x: hidden;
}

.variantsDefinitionsContainer {
  .variantDefinitionHeaderTitleContainer {
    padding-left: 40px;
    padding-top: 20px;
  }
  .variantDefinitionHeaderContainer {
    margin-bottom: -55px;
    margin-top: -5px;
    width: 100%;
    .variantDefinitionHeader-text {
      padding-top: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
