.purchase-form {
  .save-button {
    margin-bottom: 2px;
  }
}
.purchaseProducts-container {
  min-height: calc(100vh - 595px);
  max-height: calc(100vh - 340px);
  // height: calc(100vh - 340px);
  overflow-x: hidden;
}
.purchaseProducts-footer,
.purchaseProducts-row {
  .productNameContainer {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .productName {
    font-size: 11px;
  }
  .barcode {
    font-size: 10px;
  }
  .variant {
    font-size: 10px;
  }
  .ant-picker,
  input {
    font-size: 12px;
    line-height: 12px;
    height: 20px;

    padding: unset;
    padding-left: 5px;
    padding-right: 5px;
  }
  .ant-form-item-explain-error {
    font-size: 12px;
  }
}

.purchaseProducts-footer {
  font-size: 12px;
  margin-top: 18px !important;
}
