.ant-menu {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ant-menu-inline,
.ant-menu-inline-collapsed {
  border-right: 0px;
}

.sider {
  flex: 0 0 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
  width: 200px !important;
  min-height: 100vh;
  box-shadow: 1px 0 3px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
  &.light {
    box-shadow: 1px 0 3px 0 rgba(29, 35, 41, 0.05);
    background-color: white;
    :global(.ant-menu-light) {
      border-right-color: transparent;
    }
  }
  .anticon {
    font-size: 18px !important;
  }
}

.ant-layout-sider-collapsed {
  flex: 0 0 65px !important;
  max-width: 65px !important;
  min-width: 65px !important;
  width: 65px !important;
}
