.emptylayout-main-container {
  //   background: #f0f2f5;
  background: linear-gradient(135deg, #f0f2f5 0%, #ffffff 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  .logo {
    position: relative;
    overflow: hidden;
    margin: 0px;
    margin-bottom: 20px;
  }
}
