.purchaseoverview-purchaseProducts-container {
  .productName,
  .barcode,
  .batchNumber,
  .expiryDate,
  .quantity,
  .purchasePrice {
    font-size: 12px;
  }
  .variant {
    font-size: 10px;
  }
  .barcode {
    font-size: 12px;
  }
  .expand-purchaseProducts-header {
    font-size: 12px;
  }
  .ant-switch-inner {
    font-size: 10px;
  }
  button {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
