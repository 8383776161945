.resetPassword-form-container {
  top: 45%;
  position: absolute;
  width: 100%;
  margin-top: -200px;
  z-index: 100;
}
.resetPassword-form {
  max-width: 300px;
  margin: 0 auto;
}
.resetPassword-success {
  max-width: 500px;
  margin: 0 auto;
}
.resetPassword-form-button {
  width: 100%;
  margin-bottom: 15px;
}
