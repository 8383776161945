.login-form-container {
  top: 45%;
  position: absolute;
  width: 100%;
  margin-top: -200px;
  z-index: 100;
}
.login-form {
  max-width: 300px;
  margin: 0 auto;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
  margin-bottom: 15px;
}
