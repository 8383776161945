.signup-form-container {
  top: 45%;
  position: absolute;
  width: 100%;
  margin-top: -300px;
  z-index: 100;
}
.signup-form {
  max-width: 300px;
  margin: 0 auto;
}
.save-button {
  width: 100%;
  margin-bottom: 15px;
}
