.report-footer-container {
  width: 100%;
  padding-top: 10px;
  float: right;
  .secondaryTitle {
    font-size: 14px;
    font-weight: bold;
  }
  .secondaryText {
    font-size: 14px;
  }
  .primaryTitle {
    font-size: 18px;
    font-weight: bold;
  }
  .primaryText {
    font-size: 16px;
    font-weight: bold;
  }
}
