.site-tag-plus {
  background: #fff;
  border-style: dashed;
}
.edit-tag {
  user-select: none;
  margin-bottom: 10px;
}
.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}
[data-theme="dark"] .site-tag-plus {
  background: transparent;
  border-style: dashed;
}
